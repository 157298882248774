
  
  body {
    transition: background-color 0.3s ease, color 0.5s ease;
    background: var(--main-bg-color);
    color: var(--main-text-color);
  }


  
  .button {
    text-align: center;
    background-color:transparent;
    margin: 10px;
   
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 25px;
    
    border-style: solid;
    border-width: 2px;
    border-color: #4343;
   border: none !important;
    outline: none;
  }
  
  .button:hover {
    opacity: 95%;
  }
  
  .button:active {
   
    transform: translateY(4px);
  }
  
  /* Styles set when in light mode */
  body {
    --main-bg-color: rgb(255, 255, 255);
    --main-text-color: #000000;
  }
  
  /* Styles set when in dark mode */
  .dark-mode {
    --main-bg-color:#00032a;
    --main-text-color: #ffffff;
  }
  


























  