.app__navbar {
    width: 100%;
    display: flex;
background-color: #080a3e00;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
  

}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.app__navbar-logo img {
    width: 160px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
position: relative;
top: 7px;
color: #fff;
text-decoration: none;
    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}



.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 4rem;
    text-decoration: none;
    transition: .5s ease;
}



.app__navbar-login div {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000;
    transition: .5s ease;


    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color:#fff;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links .li {
    margin: 1.8rem;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
  
}



@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
        text-decoration: none;
        color: #fff;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
      
    }

    .app__navbar-logo img {
        width: 140px;
    }
}

.nav-link {
    color: #ffffff !important;
    text-decoration: none !important;
}

