

.swiper {
  width: 100%;
  
  
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 400px;
}

.swiper-slide img {
 
  width: 100%;
}





















































.swiper-nav-btns{
 text-align: center;
}




.swiper_button_css{
  background: transparent !important;
  border: none !important;
  
}



.swiper_button_css:focus {
   outline: none !important;
   outline:  none !important; 
}

.swiper_icons{
  color: rgb(255, 255, 255) !important;
  font-size: 40px !important;
}
































































































































:root {
  --swiper-image-ratio: 5.3%; /* ratio 16:9 = 56.25% */

  --swiper-width: 30%;
  --swiper-inactive-scale: .65; /* makes the other slide smaller */
  
  /* responsive settings */
  --swiper-mobile-width: 90%;
  --swiper-mobile-inactive-scale: .95;
}





.swiper-slide {
  position: relative;
  width: var(--swiper-width);
  opacity: .5;
  transform: scale(.84);
  transition: all .2s ease-in-out;
  overflow: hidden;
  border-radius: 0.2285rem;
}

.swiper-backface-hidden .swiper-slide {
  transform: scale(.84) translateZ(0);
}
        
.swiper-slide.swiper-slide-active {  
  transform: scale(1) !important;
  opacity: 1 !important;
}

.swiper-backface-hidden .swiper-slide.swiper-slide-active {
  transform: scale(1) translateZ(0) !important;
}

.swiper-image {
  position: relative;
  width: 100%;
  padding-top: var(--swiper-image-ratio);
}
    
.swiper-image .image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #929ec9;
}

.swiper-button-next,
.swiper-button-prev {
  padding: 8px;
  width: 12px;
  height: 12px;
  margin-top: 0;
  background-color: rgba(0, 0, 0, .4);
  border-radius: 50%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #fff;
  font-size: 12px;
}

.swiper-button-next {
  transform: translate(50%, -50%);
  right: calc((100% - var(--swiper-width)) / 2);
}

.swiper-button-prev {
  transform: translate(-50%, -50%);
  left: calc((100% - var(--swiper-width)) / 2);
}

@media only screen and (max-width: 768px) {
  .swiper {
    height: calc(var(--swiper-mobile-width) * var(--swiper-image-ratio) / 100%);
  }

  .swiper-slide {
    width: var(--swiper-mobile-width);
    transform: scale(var(--swiper-mobile-inactive-scale));
  }
  
  .swiper-backface-hidden .swiper-slide.swiper-slide {
    transform: scale(var(--swiper-mobile-inactive-scale)) translateZ(0);
  }

  .swiper-button-next {
    right: calc((100% - var(--swiper-mobile-width)) / 2);
  }

  .swiper-button-prev {
    left: calc((100% - var(--swiper-mobile-width)) / 2);
  }
}






