*{
  font-family: 'Poppins', sans-serif !important;
}

.background_hero{
  background-image: url('../src//image/herotop.png');
  background-size: cover;
  background-repeat: no-repeat;

  height: 100%;
}



@media screen and (min-width: 1000px) {
  
.background_hero{
  background-image: url('../src//image/herotop.png');
  background-size: cover;
  background-repeat: no-repeat;

  height: 100%;
}

  
  
}


.top_hero_text{
styleName: Hairline 2;

font-size: 12px;
font-weight: 700;
line-height: 12px;
letter-spacing: 0em;
text-align: center;
color: #777E90;

}


.top_hero_under_text{
styleName: Headline 1;

font-size: 64px;
font-weight: 500;
line-height: 64px;
letter-spacing: -0.02em;
text-align: center;
color:  #FCFCFD;
}

.top_hero_under_button {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.top_hero_under_button_css{
  border-radius: 20px !important;
  border: 1.8px solid #777E90 !important;
  color: #ffffff !important;

}



.top_hero_under_text_sign_up{
  styleName: Headline 1;
 
text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.02em;
 

  }
  @media screen and (min-width: 1000px) {
      
.top_hero_under_text_sign_up{
  styleName: Headline 1;
text-align: start;
  font-size: 74px;
  font-weight: 700;
  line-height: 98px;
  letter-spacing: -0.02em;
 
 
  
  }
      
      
    }
   




  .Under_text_sign{

      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 39px;
      
      
      letter-spacing: -0.656526px;
      
      color: rgba(255, 255, 255, 0.7);
      }


      @media screen and (min-width: 1000px) {
          
  .Under_text_sign{

      font-style: normal;
      font-weight: 300;
      font-size: 17px;
      line-height: 39px;
      
      
      letter-spacing: -0.656526px;
      
      color: rgba(255, 255, 255, 0.7);
      }

          
          
        }

      

      .top_hero_under_button_css_up{
          border-radius: 20px !important;
          color: #ffffff !important;
          background: #1647FB !important;
          color: #ffffff !important;
      
      }

      
.top_hero_under_button_css_blue{
  border-radius: 20px !important;
  color: #ffffff !important;
  background: #1647FB !important;
  width: 100% !important;
height: 40px !important;

}


@media screen and (min-width: 1000px) {
  .top_hero_under_button_css_blue{
    font-family: 'Poppins';
    font-style: normal;
      border-radius: 20px !important;
      color: #ffffff !important;
      background: #1647FB !important;
      width: 164px !important;
  height: 38px !important;
  
  }
  
  
}


.top_hero_under_button_css_login{
  border-radius: 30px !important;
  border: 2px solid  !important;

  width: 100% !important;
height: 45px !important;
justify-content: center;
align-items: center;
text-align: center;


}



@media screen and (min-width: 1000px) {
 
.top_hero_under_button_css_login{
  border-radius: 30px !important;
  border: 3px solid!important;

  width: 194px !important;
height: 48px !important;
font-size: 14px !important;
font-weight: 900 !important;

}

  
  
}





    .zoom {
    
      transition: transform .2s;
    
    }
    
    .zoom:hover {
      -ms-transform: scale(1.1); /* IE 9 */
      -webkit-transform: scale(1.1); /* Safari 3-8 */
      transform: scale(1.1); 
    }



  .center{
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
  }  








  

.background_hero_ticker{
  background-image: url('../src//image/tickos.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}



@media screen and (min-width: 1000px) {
  
.background_hero_ticker{
  background-image: url('../src//image/tickos.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}
}




.overlay::before, .overlay::after {
   background:none !important;
  content: "";
  height: 100%;
  position: absolute;
  width: var(--gradient-width);
  z-index: 2;
}

.mar_wrap{
  margin: 10px 50px;
}

.mar_wrap img {
  width:  100%;
}

@media screen and (min-width: 1000px) { 
  .mar_wrap img {
    width:  100%;
  } }


  .width_marq_os{
    width: 40% !important;
  }



@media screen and (min-width: 1000px) { 
  .width_marq_os{
width: 60% !important;
  } }










  

.background_hero_ticker_faq{
  background-image: url('../src//image/bfaqq.png');
  background-size: cover;
  background-repeat: no-repeat;

  height: 100%;
}



@media screen and (min-width: 1000px) {
  
.background_hero_ticker_faq{
  background-image: url('../src//image/bfaqq.png');
  background-size: cover;
  background-repeat: no-repeat;

  height: 100%;
}
}




.footer_bg{
  background-image: url('../src//image/footti.png');
  background-size: cover;
  background-repeat: no-repeat;

  height: 100%;
}





























.faq_top{
  font-size: 12px !important;
  font-weight: 600 !important;
}



@media screen and (min-width: 1000px) {
  
.faq_top{
  font-size: 15px !important;
  font-weight: 700 !important;
}

  
  
}







.faq_under{
  font-size: 11px !important;
  font-weight: 500 !important;
  padding: 10px;
}



@media screen and (min-width: 1000px) {
  
.faq_under{
  font-size: 14px !important;
  font-weight: 500 !important;
}

  
  
}






.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none !important;
}

.nav_text_drop .dropdown-menu {
  background-color: rgba(18, 18, 34, 0.788);
  border-radius: 10px;
}

.nav_text_drop .dropdown-item {
  color: white;
}



.my-icon  {
  color: rgb(255, 255, 255) !important;
  font: 900;
  font-size: 30px;
}


.width_press{
width: 100%;
box-shadow: none !important;
border: 3px solid #000;
}

@media screen and (min-width: 1000px) {
  
  .width_press{
    width: 100%;
    box-shadow: none !important;
  }
  }
















  
.background_heror{
  background-image: url('../src//image/rol.png');
  background-size: cover;
  background-repeat: no-repeat;

  height: 50%;
}



@media screen and (min-width: 1000px) {
  
.background_heror{
  background-image: url('../src//image/rol.png');
  background-size: cover;
  background-repeat: no-repeat;

  height: 50%;
}

  
  
}



.roadmap_titles{
text-align: center;

font-size: 45px;
font-weight: 900;

}



.message {
  background-color: transparent;
  color: #ffffff;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  padding-left: 0.5rem;
  top: 0.2rem;
  left: 270px;
  animation: openclose 5s ease-in-out infinite;
}

.word1, .word2, .word3 {
  font-family: tahoma;
}

@keyframes openclose {
  0% {
    top: 0.2rem;
    width: 0;
  }
  5% {
    width: 0;
  }
  15% {
    width: 230px;
  }
  30% {
    top: 0.2rem;
    width: 230px;
  }
  33% {
    top: 0.2rem;
    width: 0;
  }
  35% {
    top: 0.2rem;
    width: 0;
  }
  38% {
    top: -4.5rem;
    
  }
  48% {
    top: -4.5rem;
    width: 190px;
  }
  62% {
    top: -4.5rem;
    width: 190px;
  }
  66% {
    top: -4.5rem;
    width: 0;
    text-indent: 0;
  }
  71% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  86% {
    top: -9rem;
    width: 285px;
  }
  95% {
    top: -9rem;
    width: 285px;
  }
  98% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}



















/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #ffffff;
  border-radius: 18px;
  border-style: none;
  border: 2px solid #000;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;

  font-size: 12px;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 23px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}



.undercard_head{
  font-size: 28px;
  font-weight: 800;
}

@media screen and (min-width: 1000px) { 
  .undercard_head{
   font-size: 38px;
   font-weight: 800;
   text-align: start !important;
  } }


  .undercard_heads{
    font-size: 14px;
    font-weight: 800;
  }
  
  @media screen and (min-width: 1000px) { 
    .undercard_heads{
     font-size: 22px;
     font-weight: 800;
  
    } }


  .undercard_heade{
    font-size: 28px;
    font-weight: 800;
  }
  
  @media screen and (min-width: 1000px) { 
    .undercard_heade{
     font-size: 32px;
     font-weight: 800;
     text-align: end !important;
    } }



  .start_under_button{
    text-align: center ;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 1000px) {  
    .start_under_button{
      text-align: start ;
      align-items: start ;
      justify-content: start ;
    }}

    .start_under_buttone{
      text-align: center ;
      align-items: center ;
      justify-content: center ;
    }
  
    @media screen and (min-width: 1000px) {  
      .start_under_buttone{
        text-align: end;
        align-items: end ;
        justify-content: end ;
      }}

.undercard_para{
  font-size: 12px;
  font-weight: 400;
}

@media screen and (min-width: 1000px) {
  .undercard_para{
   font-size: 17px;
   font-weight: 400;
   text-align: start !important;
  }
   }


   .undercard_parae{
    font-size: 12px;
    font-weight: 400;
  }
  
  @media screen and (min-width: 1000px) {
    .undercard_parae{
     font-size: 17px;
     font-weight: 400;
     text-align: end !important;
    }
     }
  .avatar {
  
    transform: translatey(2);
    animation: float 6s ease-in-out infinite;
  }
  
  @keyframes float {
    0% {
     
      transform: translatey(2);
    }
    50% {
    
      transform: translatey(-20px);
    }
    100% {
      
      transform: translatey(2);
    }
  }


























  













/* CSS */
.button-173 {
  align-items: center;
  appearance: none;
  background-color: #ffffff;
  border-radius: 18px;
  border-style: none;
  border: 2px solid #000;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;

  font-size: 12px;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 23px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-173:hover {
  background: #F6F9FE;
  color: #174ea6;
}

.button-173:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-173:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-173:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-173:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-173:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-173:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-173:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}


.center_button{
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
}



.neon {
  
  text-shadow:
    0 0 5px #ffffff21,
    0 0 10px #ffffff5b,
    0 0 10px #ffffff7c,
    0 0 10px rgba(161, 0, 201, 0.548),
    0 0 10px rgba(4, 0, 255, 0.24),
    0 0 10px rgba(153, 46, 4, 0.445),
    0 0 10px rgba(0, 255, 255, 0.192),
    0 0 10px rgba(10, 126, 0, 0.253);
}



.contact_head{
  font-size: 35px;
}

@media screen and (min-width: 1000px) { 
  .contact_head{
font-size: 65px;
  } }


  .contact_under{
    font-size: 11px;
  }
  
  @media screen and (min-width: 1000px) { 
    .contact_under{
  font-size: 15px;
    } }


    .label_font{
      font-size: 15px;
    }


    @media screen and (min-width: 1000px) { 
      .label_font{
        font-size: 22px;
      } }


    





      .addwaves_bg{
        background-image: url('../src//image/wavez.png');
        background-size: cover;
        background-repeat: no-repeat;
      
        height: 100%;
      }








      .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
       
        min-height: 60px !important;
      
    }

    .wave_press_bg{
      background-image: url(./components/Press/wave.png);
      background-color: rgba(0, 0, 0, 0);
      background-blend-mode: multiply;
      background-size: cover;
      background-position: center;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }













    
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}








.hero_parallex{
  width: 100%;
  border-radius: 1px;
  height: 100%;
}

@media screen and (min-width: 1200px) {
  .hero_parallex{
    width: 100%;
    border-radius: 10px;
    position: relative;
    top: -10px;
  }
}
















.top_hero_under_text_sign_upp{
  styleName: Headline 1;
 
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.04em;
 

  
  }
  @media screen and (min-width: 1000px) {
      
.top_hero_under_text_sign_upp{
  
text-align: start;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;

 
  
  
  }
      
      
    }





    .addpress_bg{
      background-image: url('../src//image/pressbg.png');
      background-repeat: no-repeat;
  

        width: 100% !important;
         
    
      height: 100%;
    }


    @media screen and (min-width: 1000px) {
      
      .addpress_bg{
        background-image: url('../src//image/pressbg.png');
        background-repeat: no-repeat;
    
        background-position: center center;
          width: 100% !important;
           
      
        height: 100%;
      }
    
            
          }

    .addcard_bg{
      background-image: url('../src//image/backbutoo.png');
      background-repeat: no-repeat;
  
   
        width: 100% !important;
         
    
      height: 100%;
    }



    

    
    .subs_bg{
      background-image: url('../src//image/subsbg.png');
       background-repeat: no-repeat;
  
  background-position: center center;
    width: 100% !important;
      height: 60% !important;
    }


    .logo_ticker{
      width: 100%;
      height: 45px;
    }

    @media screen and (min-width: 1000px) {
      
      .logo_ticker{
           width: 100px;
           height: 55px;
      }
    
            
          }

          .logo_tickerlo{
            width: 100%;
            height: 65px;
          }

          @media screen and (min-width: 1000px) {
      
            .logo_tickerlo{
              width: 130px;
              height: 75px;
            }
          
                  
                }


.back_footer_accord{
  background-color: transparent !important;
  box-shadow: none !important;
  text-align: center !important;
}



.herodesk{
  position: relative;
  margin: 0;
  padding: 0;
  height: 102vh;
  background-image: url('./components/PageEnd/fofbgs.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.herodesks{
  position: relative;
  margin: 0;
  padding: 0;
  height: 102vh;
  background-image: url('./components/PageEnd/fofmb.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.head_fof{
  color: #fff;
  font-size: 130px;
}


@media screen and (min-width: 800px) {
  .head_fof{
    color: #fff;
    font-size: 250px;
  }
  

        
      }
.head_fofs{
  color: #fff;
  font-size: 20px;
}

@media screen and (min-width: 800px) {
      
  .head_fofs{
    color: #fff;
    font-size: 40px;
  }
  

        
      }














  
    .logo_ticker{
      width: 100%;
      height: 45px;
    }

    @media screen and (min-width: 1000px) {
      
      .logo_ticker{
           width: 100px;
           height: 40px;
      }
    
            
          }    


   .logo_ticker_oth{

   }       


   @media screen and (min-width: 1000px) {
      
    .logo_ticker_oth{
         width: 100px;
         height: 80px;
    }
  
          
        }  



   .logo_ticker_phool{
    width: 100px;
    height: 70px;
   }     



   @media screen and (min-width: 1000px) {
      
    .logo_ticker_phool{
         width: 100px;
         height: 70px;
    }
  
          
        }  







        ::-webkit-scrollbar {
          width: 15px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1; 
        }
         
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #041b9c; 
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #3b6fff; 
        }