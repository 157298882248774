
.field {
    background: #0000006c !important;
    color: #ffffff !important;
   
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
  border: 1px solid #fff !important;
  border-radius: 10px !important;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    color: #ffff !important;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
}