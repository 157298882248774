.timeline{
	width:100%;
	background-color:#03134791;
	color:#fff;
	padding:30px 20px;
	box-shadow:0px 0px 10px rgba(49, 100, 241, 0.5);
  border-radius: 50px 30px 50px 30px;
}
.timeline ul{
	list-style-type:none;
	border-left:2px solid #1db7ff;
	padding:10px 5px;
}
.timeline ul li{
	padding:20px 20px;
	position:relative;
	cursor:pointer;
	transition:.5s;
}
.timeline ul li span{
	display:inline-block;
	background-color:#ff29a6fa;
	border-radius:50%;
	padding:2px 10px;
	font-size:20px;
	text-align:center;
}
.timeline ul li .content h3{
	color:#34ace0;
	font-size:17px;
	padding-top:5px;
}
.timeline ul li .content p{
	padding:5px 0px 15px 0px;
	font-size:15px;
}
.timeline ul li:before{
	position:absolute;
	content:'';
	width:10px;
	height:10px;
	background-color:#34ace0;
	border-radius:20px 20px 0px 0px; 
	box-shadow:0px 0px 10px rgba(49, 100, 241, 0.5);
	left:-11px;
	top:28px;
	transition:.5s;
}
.timeline ul li:hover{
	background-color:#83d6fca6;
	border-radius:50px 10px 50px 10px; 
}
.timeline ul li:hover:before{
	background-color:rgb(255, 39, 219);
	
	box-shadow:0px 0px 10px rgba(49, 100, 241, 0.5);
}
@media (max-width:300px){
	.timeline{
		width:100%;
		padding:30px 5px 30px 10px;
	}
	.timeline ul li .content h3{
		color:#34ace0;
		font-size:15px;
	}

}