


  .utilityhead{
    align-items: center;
    text-align: center;
    justify-content: center;
  font-size: 24px;
  font-weight: 700;
  }

  @media screen and (min-width: 1000px) {
          
    .utilityhead{
      align-items: center;
      text-align: center;
      justify-content: center;
    font-size: 60px;
    font-weight: 800;
    }  
          }
  
  .utilityhead_under{
    align-items: center;
    text-align: center;
    justify-content: center;
  font-size: 14px;
  font-weight: 400;

          }
        


          @media screen and (min-width: 1000px) {
          
            .utilityhead_under{
              align-items: center;
              text-align: center;
              justify-content: center;
            font-size: 18px;
            font-weight: 500;
          
                    } 
                  }

.card_bg{
  background-color: transparent !important;
  box-shadow: none !important;
}



.container {
  display: flex;
  height: 100% !important; 
}




.custom-search {
  position: relative;
  width: 100%;

}
.custom-search-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 10px 100px 10px 20px; 
  line-height: 1;
  box-sizing: border-box;
  outline: none;
}
.custom-search-botton {
  position: absolute;
  right: 5px; 
  top: 6px;
  bottom: 3px;
  border: 0;
  border: 1px solid #000;
         
  background-image:
linear-gradient(90deg, #E371F5 0%, #5649F5 41.26%, #2CCAFA 96.63%);
border-radius: 10px;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 10px;
  border-radius: 100px;
  z-index: 2;
  height: 35px;
}



@media screen and (min-width: 1000px) {

  .custom-search {
    position: relative;
    width: 43%;
   
  
  }
  .custom-search-input {
    width: 100%;
    border: 2px solid #000000;
    border-radius: 10px;
    padding: 10px 100px 10px 20px; 
    line-height: 1;
    box-sizing: border-box;
    outline: none;
   
  }
  .custom-search-botton {
    position: absolute;
    right: 2px; 
    top: 2px;
    border: 1px solid #00000079;
    bottom: 30px !important;
    border: 1px solid #000;
         
          background-image:
linear-gradient(90deg, #E371F5 0%, #5649F5 41.26%, #2CCAFA 96.63%);
border-radius: 10px;
    color: #ffffff;
    outline: none;
    margin: 0;
    padding: 0 10px;
    border-radius: 5px;
    z-index: 2;
    height: 45px;
   
  }
  
        }



        
        .custom-search-bottonn {
          
         
          border: 1px solid #000;
         
          background-image:
linear-gradient(90deg, #E371F5 0%, #5649F5 41.26%, #2CCAFA 96.63%);
border-radius: 10px;
          color: #fff;
          transition: 0.5s;
          outline: none;
          margin: 0;
          padding: 0 10px;
          
          z-index: 2;
          width: 140px;
          height: 42px;
        }
        .custom-search-bottonn:hover {
          background-position: right center; /* change the direction of the change here */
        }


      .center_news_sub_btn{
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;
      }  














